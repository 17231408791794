import { patterns } from './actions';

export default function account(state = {}, action) {
  switch (true) {
    case patterns.resolve(action):
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export const selectAccountId = state => state.account.accountId;
export const selectEmail = state => state.account.email;
export const selectIsSpoofing = state => state.account.isSpoofing;
export const selectUserId = state => state.account.userId;
export const selectVersion = state => state.account.version;
export const selectTier = state => state.account?.tier;
export const selectCreatedAt = state => state.account?.createdAt;
